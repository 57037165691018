/* You can add global styles to this file, and also import other style files */
//var(--atelier2-color-brown)
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');
@import './fluid-font.scss';
@import './mixin.scss';
@import url(~fullpage.js/dist/fullpage.min.css);

:root {

    --agence-pure-font-family: 'Raleway', sans-serif;
    --agence-pure-alt-font-family: 'Manrope', sans-serif;
    --agence-pure-roboto-font-family: 'Roboto', sans-serif;
    --agence-pure-color-black: #191919;
    --agence-pure-color-pure-black: #000;
    --agence-pure-color-white: #fff;
    --agence-pure-color-pure-white: #fff;
    --agence-pure-color-snow: #F6F4F1;
    --agence-pure-color-kaki: #9C974F;
    --agence-pure-color-feed: #D9D9D9;
    --agence-pure-color-grey: #727272;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

*:focus{
    outline: none;
}

html{
    background: var(--agence-pure-color-snow);
}

body{
    font-family: var(--agence-pure-alt-font-family);
    background-color: var(--agence-pure-color-snow);
    @include fluid-type(16px, 18px);
}
header{
    opacity: 1;
    transition: all ease .4s;
}
body.anim-home header{
    opacity: 0;
}
.scroller.active{
    height: 100vh;
}
body:not(.smoothed){
    height: auto;
    overflow: overlay;
}
body.no-overflow{
    overflow: hidden;
}
body.hide-header header{
    opacity: 0;
}

body::-webkit-scrollbar-track
{
	border-radius: 0px;
	background-color: transparent;
}

body::-webkit-scrollbar
{
	width: 17px;
	background-color: transparent;
}

body::-webkit-scrollbar-thumb
{
	border-radius: 0px;
    background-color: var(--agence-pure-color-kaki);
}
body .scrollbar-track-y{
	width: 17px;
    background-color: transparent;
}
body .scrollbar-thumb{
	width: 17px;
	border-radius: 0px;
    background-color: var(--agence-pure-color-kaki);
    position: relative;
    &:before{
        position: absolute;
        bottom: 100%;
        left: 0;
        height: 100vh;
        width: 100%;
    }
}

img{
    max-width: 100%;
}

a{
    cursor: pointer;
    text-decoration: none;
    color: inherit;
}


/* fix slick slider height */
.slick-track{
    height: 100%;
}
.slick-list{
    height: 100%;
}
.slick-slider{
    height: 100%;
}
#metiers-section .slick-track
{
    display: flex !important;
}

#metiers-section .slick-slide
{
    height: inherit !important;
}
.slick-slider *:focus{
    outline: none !important;
}
#all .slider-projet{
    position: relative;
    .prevArrow{
        padding: 5px;
        cursor: pointer;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%) rotate(180deg);
        opacity: .2;
        transition: all ease .4s;
        z-index: 100000;
        &:hover{
            opacity: 1;
        }
        svg{
            display: block;
        }
    }
    .slick-list{
        z-index: 100;
    }
    .nextArrow{
        padding: 5px;
        cursor: pointer;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        opacity: .2;
        transition: all ease .4s;
        z-index: 100000;
        &:hover{
            opacity: 1;
        }
        svg{
            display: block;
        }
    }
}
#equipe-section{
    .animEquipeSlide+div{
        .img-container{
            img{
                margin-right: 20% !important;
                margin-left: 0 !important;
            }
        }
    }
}
#all{
    .slick-track{
        margin-left: 0;
        margin-right: auto;
    }
}
html, body { height: 100%; }
body { 
    margin: 0; 
}


#ngrecaptcha-0{
    @include media-min("phone-down") {
        display: block;
        margin: auto;
        transform:scale(0.77);
        -webkit-transform:scale(0.77);
        transform-origin:0 0;
        -webkit-transform-origin:0 0;
    }
}



#decouvrir{
    background: linear-gradient(90deg, var(--agence-pure-color-snow) 50%, var(--agence-pure-color-kaki) 50%);
    .container{
        display: flex;
        flex-wrap: wrap;
        .col{
            width: 50%;
            padding: 110px 140px;
            &:first-of-type{
                text-align: right;
                @include media-min("phone-down") {
                    text-align: center;
                    padding-bottom: 20px;
                }
            }
            &:last-of-type{
                padding-right: 20px;
            }
            h2{
                @include fluid-type(20px, 28px);
                font-weight: 400;
            }
            .link-bt{
                display: block;
                margin-bottom: 36px;
                @include media-min("phone-down") {
                    margin-bottom: 20px;
                }
                &:last-of-type{
                    margin-bottom: 0;
                }
            }
            @include media-min("fullpage-down") {
                padding: 100px 70px;
                &:last-of-type{
                    padding-right: 20px;
                }
            }
            @include media-min("tablet-down") {
                padding: 70px 40px;
                &:last-of-type{
                    padding-right: 20px;
                }
            }
            @include media-min("phone-down") {
                padding: 0;
                width: 100%;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
            }
        }
    }
    @include media-min("phone-down") {
        background: var(--agence-pure-color-kaki);
        padding: 60px 20px;
    }
}

#scrolltop{
    mix-blend-mode: difference;
    position: fixed;
    bottom: 20px;
    right: 40px;
    cursor: pointer;
    z-index: 1000;
}

/* REF SLIDER */
#reference-section .slick-list{
    @include media-min("fullpage-down") {
        //overflow: visible;
    }
}

.h1-container{
    overflow: hidden;
}

/* couleur lien cas client */
.cas-client-decouvrir-site{
    color: var(--agence-pure-color-kaki);
}
.cas-client-decouvrir-site:hover{
    text-decoration: underline;
}

/* fix ref */

.infos-ref{
    h3{
        .small{
            font-weight: 400;
            @include fluid-type(20px, 40px);
        }
    }
}