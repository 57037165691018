$breakpoints: (
    "phone-down": 480px,
    "tablet-small": 650px,
    "tablet-down": 768px,
    "fullpage-down": 992px,
    "desktop-up": 1024px,
    "desktop-down": 1280px,
    "fullpagejs": 1365px,
    "widescreen-up": 1440px
);
@mixin media-min($_key) {
    @media screen and (max-width: map-get($breakpoints, $_key)) {
        &{ @content; }
    }
}